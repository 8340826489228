import slugify from 'slugify';
import { useAuthStore } from '~/auth/stores/auth.store';
import { formatDropdownRadioValue, formatLabelsCheckboxValue, formatMoneyValue, formatNumberValue, formatPercentageValue, formatPlannedActualValue } from '~/common/components/molecules/hawk-custom-field-value-render/custom-field-formatter.js';

import { useCommonStore } from '~/common/stores/common.store';
import { $date } from '~/common/utils/date.util';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

function formatCustomFieldValue(field, value) {
  const { get_user_or_team_name } = useCommonStore();

  switch (field.type) {
    case 'date':
      return $date(value, 'DD MMM YYYY');
    case 'datetime':
      return $date(value, 'DD MMM YYYY, HH:MM');
    case 'date_range':
      if (value?.start && value?.end)
        return `${$date(value.start, 'DD MMM YYYY')} → ${$date(value.end, 'DD MMM YYYY')}`;
      else return '-';
    case 'members':
      return (value || []).map(uid => get_user_or_team_name(uid)).join(', ');
    case 'percentage':
      return formatPercentageValue(value);
    case 'planned_actual':{
      return formatPlannedActualValue(field, value);
    }
    case 'dropdown':
    case 'radio':
      return formatDropdownRadioValue(field, value);
    case 'number':
      return formatNumberValue(field, value);
    case 'money':
      return formatMoneyValue(field, value);
    case 'labels':
    case 'checkbox':{
      return formatLabelsCheckboxValue(field, value);
    }
    case 'files':
      return (value || []).map(file => file.file_name).join(', ');
    default:
      return value || '-';
  }
}

function getAssetCustomFields(active_asset, assets_custom_fields_map) {
  slugify.extend({ '/': '-', '(': '', ')': '', '*': '', '&': '-' });
  if (active_asset?.properties?.metadata_visibility) {
    return active_asset?.properties?.metadata_visibility.reduce((res, field_uid) => {
      const field = active_asset?.metadata?.find(f => f?.field === field_uid);

      if (field) {
        const custom_field_key_slug = slugify(assets_custom_fields_map?.[field.field]?.name.replaceAll(/[^\x00-\x7F]/g, '').replaceAll('.', '-'), { lower: true });
        res[custom_field_key_slug] = formatCustomFieldValue(assets_custom_fields_map?.[field.field], field.value);
      }
      return res;
    }, {});
  }
  return active_asset?.metadata.reduce((res, item) => {
    const custom_field_key_slug = slugify(assets_custom_fields_map?.[item.field]?.name.replaceAll(/[^\x00-\x7F]/g, '').replaceAll('.', '-'), { lower: true });
    res[custom_field_key_slug] = formatCustomFieldValue(assets_custom_fields_map?.[item.field], item.value);
    return res;
  }, {});
}

export function getCustomPrintMeta() {
  const { current_dashboard, scope } = useDashboardStore();
  const { active_asset, assets_custom_fields_map, get_user_or_team_name } = useCommonStore();
  const auth_store = useAuthStore();

  return {
    dashboard_name: current_dashboard?.name || 'Untitled',
    organization_name: auth_store?.current_organization?.name || '',
    has_organization_logo: !!auth_store?.current_organization?.cover_image?.url,
    organization_logo: {
      image_url: auth_store?.current_organization?.cover_image?.url || null,
      width: 150,
      height: 50,
    },
    dashboard_scope: scope,
    // template_name: props.templateName,
    created_by: get_user_or_team_name(auth_store.logged_in_user_id) || '',
    ...(active_asset && {
      asset_name: active_asset?.name || 'Untitled',
      description: active_asset?.description || '',
      asset_address: active_asset?.address?.name || '',
      code: active_asset?.code || '',
      has_asset_logo: !!active_asset?.cover_image?.url,
      asset_logo: {
        image_url: active_asset?.cover_image?.url || null,
        width: 200,
        height: 100,
      },
      asset_custom_fields: getAssetCustomFields(active_asset, assets_custom_fields_map),
    }),
  };
}
