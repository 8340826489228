import { debounce, keyBy } from 'lodash-es';
import { acceptHMRUpdate, defineStore } from 'pinia';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useI18nStore } from '~/common/stores/i18n.store';
import DmsColumnSelectorTemplate from '~/dashboard/components/vueform-schema-templates/dms-column-selector-template.vue';
import SelectFolderTemplate from '~/dashboard/components/vueform-schema-templates/select-folder-template.vue';
import SelectOptionTemplate from '~/dashboard/components/vueform-schema-templates/select-option-template.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';

export const useDashboardDMSStore = defineStore('dashboard_dms', {
  state: () => ({
    dms_configuration: {},
    transmittals_map: {},
    files_values_options: [
      ['files_count', 'Files count'],
      ['revision_count', 'Revisions count'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
      };
    }),
    transmittals_values_options: [
      ['transmittal_count', ' Transmittal count'],
      ['transmittal_overdue_count', 'Transmittal overdue count'],
    ].map((item) => {
      return {
        value: item[0],
        label: item[1],
      };
    }),
    standard_columns: [
      {
        uid: 'name',
        id: 'name',
        name: 'Name',
        label: 'Name',
        accessorKey: 'name',
        static: true,

      },
      {
        uid: 'status',
        id: 'status',
        name: 'Status',
        label: 'Status',
        accessorKey: 'status',
      },
      {
        uid: 'number',
        id: 'number',
        name: 'Number',
        label: 'Number',
        accessorKey: 'number',
      },
      {
        uid: 'updated_at',
        id: 'updated_at',
        name: 'Modified On',
        label: 'Modified On',
        accessorKey: 'updated_at',
      },
      {
        uid: 'category',
        id: 'category',
        name: 'Category',
        label: 'Category',
        accessorKey: 'category',
      },
    ],
    data_set: false,
  }),
  getters: {
    type_options() {
      const list = this.dms_configuration.data_source !== 'transmittals'
        ? [
            ['documents_list', 'Documents list'],
            ['vertical_graph', 'Vertical Bar Chart'],
            ['horizontal_bar', 'Horizontal Bar Chart'],
            ['donut', 'Pie Chart'],
          ]
        : [['transmittal_list', 'Transmittal list'], ['vertical_graph', 'Vertical Bar Chart'], ['horizontal_bar', 'Horizontal Bar Chart'], ['donut', 'Pie Chart'], ['transmittal_detail_view', 'Detail view']];
      return list.map((item) => {
        return {
          value: item[0],
          label: item[1],
          description: item[2],
        };
      });
    },

    custom_fields: () => {
      const dms_settings_store = useDMSSettingsStore();
      return dms_settings_store.custom_fields.filter(field => !field.deleted && field.active);
    },

    files_fields_options: (state) => {
      return [
        ['status', 'Status', null],
        ...state.custom_fields.map(a => [a.uid, a.label, a.type]),
        ['modified_on', 'Modified on', null],
        ['shared with', 'Shared with', null],
      ].map((item) => {
        return {
          value: item[0],
          label: item[1],
          type: item[2],
        };
      });
    },

    files_breakdown_options: (state) => {
      const { $t } = useI18nStore();
      return [
        ['none', $t('None'), null],
        ['status', $t('Status'), null],
        ...state.custom_fields.map(a => [a.uid, a.label, a.type]),
        ['modified_on', $t('Modified on'), null],
        ['shared with', $t('Shared with'), null],
      ].map((item) => {
        return {
          value: item[0],
          label: item[1],
          type: item[2],
        };
      });
    },

    transmittals_fields_options: (state) => {
      return [
        [
          'status',
          'Status',
          null,
        ],
        [
          'issue_purpose',
          'Issue purpose',
          null,
        ],
        [
          'priority',
          'Priority',
          null,
        ],
        [
          'category',
          'Category',
          null,
        ],
        [
          'created_on',
          'Created_on',
          null,
        ],
        ...state.custom_fields.map(a => [a.uid, a.label, a.type]),
        [
          'submitters',
          'Submitters',
          null,
        ],
        [
          'reviewers',
          'Reviewers',
          null,
        ],
        [
          'approvers',
          'Approvers',
          null,
        ],
        [
          'cc',
          'CC',
          null,
        ],
      ].map((item) => {
        return {
          value: item[0],
          label: item[1],
          type: item[2],
        };
      });
    },
    transmittals_breakdown_options: (state) => {
      return [
        ['none', 'None', null],
        [
          'status',
          'Status',
          null,
        ],
        [
          'issue_purpose',
          'Issue purpose',
          null,
        ],
        [
          'priority',
          'Priority',
          null,
        ],
        [
          'category',
          'Category',
          null,
        ],
        [
          'created_on',
          'Created_on',
          null,
        ],
        ...state.custom_fields.map(a => [a.uid, a.label, a.type]),
        [
          'submitters',
          'Submitters',
          null,
        ],
        [
          'reviewers',
          'Reviewers',
          null,
        ],
        [
          'approvers',
          'Approvers',
          null,
        ],
        [
          'cc',
          'CC',
          null,
        ],
      ].map((item) => {
        return {
          value: item[0],
          label: item[1],
          type: item[2],
        };
      });
    },
    dms_schema(state) {
      const { $t } = useCommonImports();

      const getOptions = options => options.map(option => ({ ...option, label: $t(option.label) }));

      if (!state.data_set)
        return {};

      return {
        data_source: {
          type: 'radiogroup',
          label: $t('Data source'),
          items: [
            {
              value: 'files',
              label: $t('Files'),
            },
            {
              value: 'transmittals',
              label: $t('Transmittals'),
            },
          ],
          default: state.dms_configuration.data_source || 'files',
        },
        ...(state.dms_configuration.data_source === 'files' && {
          folders: {
            type: 'static',
            label: $t('Folders'),
            conditions: [
              [
                'data_source',
                '==',
                [
                  'files',
                ],
              ],
            ],
            default: state.dms_configuration.folders || [],
            slots: {
              default: SelectFolderTemplate,
            },
          },
        }),
        type: {
          type: 'select',
          items: getOptions(state.type_options),
          search: true,
          native: false,
          label: $t('Type'),
          canClear: false,
          canDeselect: false,
          inputType: 'search',
          autocomplete: 'off',
          rules: [
            'required',
          ],
          default: state.dms_configuration.type || state.type_options[0].value,
          slots: {
            option: SelectOptionTemplate,
          },
        },
        ...(state.dms_configuration.data_source === 'files' && {
          files_field: {
            type: 'select',
            items: getOptions(state.files_fields_options),
            search: true,
            native: false,
            label: $t('Fields'),
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            object: true,
            rules: 'required',
            default: state.dms_configuration.files_field || ({ ...state.files_fields_options[0], label: $t(state.files_fields_options[0]?.label) }),
            conditions: [
              [
                'data_source',
                '==',
                [
                  'files',
                ],
              ],
              [
                'type',
                '!=',
                [
                  'documents_list',
                ],
              ],
            ],
          },
          files_value: {
            type: 'select',
            items: getOptions(state.files_values_options),
            search: true,
            native: false,
            label: $t('Values'),
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            object: true,
            rules: 'required',
            default: state.dms_configuration.files_value || { ...state.files_values_options[0], label: $t(state.files_values_options[0]?.label) },
            conditions: [
              [
                'data_source',
                '==',
                [
                  'files',
                ],
              ],
              [
                'type',
                '!=',
                [
                  'documents_list',
                ],
              ],
            ],
          },
          files_breakdown_by: {
            type: 'select',
            items: state.files_breakdown_options,
            search: true,
            native: false,
            label: $t('Breakdown by'),
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            object: true,
            default: state.dms_configuration.files_breakdown_by || { ...state.files_breakdown_options[0], label: state.files_breakdown_options[0]?.label },
            conditions: [
              [
                'data_source',
                '==',
                [
                  'files',
                ],
              ],
              [
                'type',
                '!=',
                [
                  'documents_list',
                  'donut',
                ],
              ],
            ],
          },
          columns: {
            type: 'static',
            label: $t('Columns'),
            rules: [
              'distinct',
              {
                required: [
                  [
                    'type',
                    'in',
                    [
                      'documents_list',
                    ],
                  ],
                ],
              },
            ],
            conditions: [
              [
                'data_source',
                '==',
                [
                  'files',
                ],
              ],
              [
                'type',
                '==',
                [
                  'documents_list',
                ],
              ],
            ],
            slots: {
              default: DmsColumnSelectorTemplate,
            },
          },
        }),
        ...(state.dms_configuration.data_source === 'transmittals' && {
          transmittals_field: {
            type: 'select',
            items: state.transmittals_fields_options,
            search: true,
            native: false,
            label: $t('Fields'),
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            object: true,
            rules: 'required',
            default: state.dms_configuration.transmittals_field || state.transmittals_fields_options[0],
            conditions: [
              [
                'data_source',
                '==',
                [
                  'transmittals',
                ],
              ],
              [
                'type',
                '!=',
                [
                  'transmittal_list',
                  'transmittal_detail_view',
                ],
              ],
            ],
          },
          transmittals_value: {
            type: 'select',
            items: state.transmittals_values_options,
            search: true,
            native: false,
            label: $t('Values'),
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            object: true,

            rules: 'required',
            default: state.dms_configuration.transmittals_value || state.transmittals_values_options[0],
            conditions: [
              [
                'data_source',
                '==',
                [
                  'transmittals',
                ],
              ],
              [
                'type',
                '!=',
                [
                  'transmittal_list',
                  'transmittal_detail_view',
                ],
              ],
            ],
          },
          transmittals_breakdown_by: {
            type: 'select',
            items: state.transmittals_breakdown_options,
            search: true,
            native: false,
            label: $t('Breakdown by'),
            canClear: false,
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            object: true,
            default: state.dms_configuration.transmittals_breakdown_by || state.transmittals_breakdown_options[0],
            conditions: [
              [
                'data_source',
                '==',
                [
                  'transmittals',
                ],
              ],
              [
                'type',
                '!=',
                [
                  'transmittal_list',
                  'donut',
                  'transmittal_detail_view',
                ],
              ],
            ],
          },
          transmittal: {
            type: 'select',
            items: Object.values(state?.transmittals_map || []),
            search: true,
            native: false,
            valueProp: 'uid',
            labelProp: 'name',
            label: $t('Transmittal'),
            canClear: true,
            onSearchChange: debounce((query) => {
              this.set_transmittals({ query: { q: query } });
            }, 400),
            canDeselect: false,
            inputType: 'search',
            autocomplete: 'off',
            conditions: [
              [
                'data_source',
                '==',
                [
                  'transmittals',
                ],
              ],
              [
                'type',
                '==',
                [
                  'transmittal_detail_view',
                ],
              ],
            ],
            default: state.dms_configuration.transmittal || [],
          },

        }),
        scope: {
          type: 'checkbox',
          text: $t('Make report data specific to the viewer'),
          default: state.dms_configuration.scope || false,
        },
        module: {
          type: 'hidden',
          default: 'dms',
        },
      };
    },
  },
  actions: {
    update_dms_configuration(data) {
      this.dms_configuration = { ...data };
    },

    set_data() {
      const dashboard_store = useDashboardStore();
      const widget_configuration = dashboard_store.widget_configuration;

      if (
        !Object.keys(widget_configuration || {}).length
      ) {
        this.data_set = true;
        this.dms_configuration = {
          ...this.dms_configuration,
          folders: [],
          asset_id: dashboard_store.widget_asset,
          columns: this.dms_configuration.columns || this.standard_columns,
        };
        return;
      }

      const data = widget_configuration;
      this.dms_configuration = { ...data };

      this.data_set = true;
    },

    async set_transmittals(payload = {}) {
      const { data } = await this.$services.dms_transmittals.getAll({
        asset: this.$router.currentRoute.value.params.asset_id,
        query: { all: true, ...(payload.query || {}) },
      });
      if (data.transmittals)
        this.transmittals_map = keyBy(data.transmittals, 'uid');
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useDashboardDMSStore, import.meta.hot));
