<script setup>
import { isEqual } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import TerraMapViewLegend from '~/dashboard/components/widgets/terra-widgets/terra-map-view-legend.vue';
import TerraMapViewLegendPopup from '~/dashboard/components/widgets/terra-widgets/terra-map-view-legend-popup.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  is_editing: {
    type: Boolean,
    default: false,
  },
});

const $t = inject('$t');

const dashboard_terra_store = useDashboardTerraStore();
const dashboard_store = useDashboardStore();

const {
  terra_configuration,
} = storeToRefs(dashboard_terra_store);

const {
  is_editing_widget,
  widget_uid,
  is_mobile_view,
} = storeToRefs(dashboard_store);

const {
  set_terra_map_data,
  update_terra_configuration,
} = dashboard_terra_store;

const {
  set_widget_configuration,
  update_terra_map_widget,
} = dashboard_store;

const is_terra_map_widget_loading = ref(false);
const show_legend = ref(false);
const show_mobile_legend = ref(false);
const legend_data = ref({});

const $services = inject('$services');

const map_data = ref({});

const snapshot_src = ref('');

// old terra map widgets that don't have projects or feature types selected
// the minimum requirement is to have projects
const is_old_data = computed(() => {
  return !props.data.data.projects?.length || (!Object.keys(props.data?.data?.map_view_data || {}).length && props.id !== 'preview');
});

async function loadLegendsData(url = null) {
  if (is_old_data.value || !url)
    return;

  try {
    const legends_url = url?.replace('/snapshot.png', '/metadata.json');

    if (legends_url) {
      const date = new Date();
      const response = await $services.common.getAll({
        url: legends_url,
        query: {
          organization: false,
          remove_slash: true,
          token: false,
          date: date.getTime(),
        },
      });

      return response.data;
    }
  }
  catch (err) {
    logger.log(err);
  }
}

async function fetchMapViewData() {
  if (is_old_data.value)
    return;

  try {
    is_terra_map_widget_loading.value = true;
    const response = await $services.tasks.get_map_snapshot({
      body: {
        widgetId: props.data.uid || widget_uid.value,
        projectIds: props.data.data?.projects,
        featureTypeIds: props.data.data?.feature_types,
      },
    });
    const legend = await loadLegendsData(response.data.url);

    const map_view_data = {
      snapshot: response.data.url,
      ...legend,
    };

    if (props.id !== 'preview')
      set_terra_map_data(map_view_data);

    if (is_editing_widget.value || props.id === 'preview') {
      update_terra_configuration({
        ...terra_configuration.value,
        map_view_data,
      });

      set_widget_configuration({
        ...terra_configuration.value,
        map_view_data,
      });
    }

    is_terra_map_widget_loading.value = false;

    return map_view_data;
  }
  catch (err) {
    logger.log(err);
    is_terra_map_widget_loading.value = false;
  }
}

async function refreshSnapshot() {
  if (is_editing_widget.value) {
    fetchMapViewData();
  }
  else {
    const map_data = await fetchMapViewData();
    await update_terra_map_widget(props.id, map_data, $t);
    updatePrintMap();
  }
}

function openLegend() {
  if (!is_mobile_view.value)
    show_legend.value = !show_legend.value;
  else
    show_mobile_legend.value = true;
}

function updatePrintMap() {
  if (!map_data.value)
    return;

  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'terra-map',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      ...map_data.value,
    },
  });

  dashboard_store.update_new_print_map((props.data.data.name), {
    widget_type: 'image',
    type: props.data?.data?.type,
    dataSource: {
      ...map_data.value,
    },
  });
}

function setMapData() {
  const data = props.data?.data?.map_view_data || {};

  // support for older widgets without legend data already saved
  if (!data?.legend) {
    map_data.value = {
      ...data,
      ...legend_data.value,
    };
  }

  map_data.value = {
    ...data,
  };

  // Random UID prevents browser caching
  snapshot_src.value = `${map_data.value.snapshot}?random=${crypto.randomUUID()}`;
}

watch(() => props.data?.data?.map_view_data, (new_val, old_val) => {
  if (isEqual(new_val, old_val))
    return;

  setMapData();

  if (props?.id !== 'preview')
    updatePrintMap();
});

onMounted(async () => {
  setMapData();
  if (props?.id !== 'preview') {
    // support for older widgets without legend data already saved
    if (!map_data.value?.legend && !Object.keys(legend_data.value || {}).length)
      legend_data.value = await loadLegendsData(map_data.value.snapshot) || {};

    updatePrintMap();
  }
});

defineExpose({ fetchMapViewData });
</script>

<template>
  <div class="h-full flex flex-col">
    <div class="widget-header group">
      <slot name="header-title" />
      <p
        v-if="map_data?.timestamp && !is_terra_map_widget_loading && props.id !== 'preview'"
        class="cursor-pointer ml-auto mr-2 flex text-sm text-gray-700 font-medium"
      >
        <HawkText :length="100" :content="`Last updated at ${$date(map_data.timestamp)}`" class="mr-2" />
        <icon-hawk-refresh-ccw-one @click="refreshSnapshot" />
      </p>
      <p
        v-if="map_data?.legend?.length && !is_terra_map_widget_loading"
        class="cursor-pointer mr-2"
        :class="{ 'ml-auto': props.id === 'preview' }"
        @click="openLegend"
      >
        <icon-hawk-info-circle class="w-5 h-5 text-gray-600" :class="{ 'text-primary-600': show_legend }" />
      </p>
      <slot name="header-actions" />
    </div>
    <HawkLoader v-if="is_terra_map_widget_loading" />
    <div v-else-if="is_old_data">
      <div class="text-sm font-medium text-center pt-8">
        {{ $t('Invalid configuration. Please reconfigure and try again.') }}
      </div>
    </div>
    <div v-else-if="map_data" class="h-[inherit]">
      <div :key="snapshot_src" class="h-[calc(100%-64px)] scrollbar" :class="[show_legend ? 'w-3/4' : 'w-full']">
        <img :src="snapshot_src" alt="terra map snapshot" class="pr-2">
      </div>
      <div v-if="!is_mobile_view" class="absolute right-0 top-12 bg-white transition-all h-[calc(100%-64px)] scrollbar -ml-1 z-10" :class="[show_legend ? 'w-[calc(25%-2px)] border-l border-gray-300' : 'w-0']">
        <TerraMapViewLegend :map_data_legend="map_data.legend || []" />
      </div>
      <div v-else>
        <TerraMapViewLegendPopup
          v-if="show_mobile_legend"
          :map_data_legend="map_data.legend || []"
          @close="show_mobile_legend = false"
        />
      </div>
    </div>
  </div>
</template>
