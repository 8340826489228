<script setup>
import { cloneDeep, isEqual, toString } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { defineAsyncComponent, inject } from 'vue';
import HawkLoader from '~/common/components/atoms/hawk-loader.vue';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardDMSStore } from '~/dashboard/store/dashboard-dms.store.js';
import { useDocumentGlobalActions } from '~/dms/composables/document-global.composable';
import { useDMSSettingsStore } from '~/dms/store/dms-settings.store';
import { useDocumentStore } from '~/dms/store/document.store';

import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  content_height: {
    type: Number,
  },
  query: {
    type: Object,
  },

  activeSelectedRange: {
    type: Array,
    default: () => [],
  },
});

const $t = inject('$t');

const DocumentTable = defineAsyncComponent({
  loader: () => import('~/dms/components/documents/table/document-table.vue'),
  loadingComponent: HawkLoader,
});

const dashboard_selected_range = inject('dashboardSelectedRange');

const dms_settings_store = useDMSSettingsStore();

const { getFormattedDate } = useFamConstants();

const document_table = ref(null);
const forceUpdate = ref(0);
const prevent_watcher = ref(false);
const document_global_actions = useDocumentGlobalActions(props.id);
const document_store = useDocumentStore(props.id);
const dashboard_store = useDashboardStore();
const dashboard_dms_store = useDashboardDMSStore();
const { dms_configuration } = storeToRefs(dashboard_dms_store);
const state = reactive({ is_table_loading: false });

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function updatePrintMap() {
  const print_dataset = document_store.documents.map((f) => {
    return {
      ...f,
      status: f.status?.name,
    };
  });
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dashboard_selected_range,
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: document_table?.value?.columns,
      activities: print_dataset,
      dashboard_index: props.data.i,
      dataset: print_dataset,

    },
  });

  const cols = (props.data.data.columns || dashboard_dms_store.standard_columns).map(a => a.accessorKey);

  const print_data = print_dataset.map(t => cols.map((a) => {
    if (a === 'due_date') {
      return t[a] ? $date(t[a], 'L_DATE_MED') : null;
    }
    else if (a === 'members') {
      const users = getMembersAndTeams(t.members, t.teams).map((u) => {
        return common_store.get_user_or_team_name(u);
      });
      return toString(users);
    }
    else {
      return toString(t[a]);
    }
  }));
  dashboard_store.update_new_print_map((props.data?.data?.name || 'untitled'), {
    widget_type: 'table',
    type: props.data?.data?.type,
    dataSource: print_data,
  });
}

function set_custom_fields() {
  dms_settings_store.set_custom_fields({
    query: {
      allowed_in: 'documents',
    },
  });
}
async function getData() {
  state.is_table_loading = true;
  await document_global_actions.getDocuments({
    query: {
      asset: dashboard_store.widget_asset,
      files: true,
      children: true,
      folders: props.data.data.folders?.map(e => e.uid),
      scope: props.data.data.scope,
      ...props.query,

    },
    filters: {
      filters: props.data?.data?.filters?.filters?.map((f) => {
        if (f.operator_option)
          return { ...f, value: getFormattedDate(f.operator_option) };
        return f;
      }),
    },
    skip_root: true,
  });
  state.is_table_loading = false;
}

if (props.id === 'preview') {
  watch(() => props.data, (new_val, old_val) => {
    if (!prevent_watcher.value && new_val && !isEqual(new_val, old_val)) {
      setTimeout(() => {
        getData();
      }, 250);
    }
  }, { immediate: true, deep: true });
}

if (props.id !== 'preview') {
  watch(() => props.data.data, (new_val, old_val) => {
    if (!prevent_watcher.value && new_val && !isEqual(new_val, old_val)) {
      setTimeout(() => {
        set_custom_fields();
        getData();
      }, 250);
    }
  }, { immediate: true, deep: true });
}

watch(() => props.activeSelectedRange, (new_val, old_val) => {
  if (!isEqual(new_val, old_val) && (props?.id !== 'preview')) {
    getData();
  }
});
async function columnResized(_resized_column, columns_widths) {
  prevent_watcher.value = true;
  if (props.id === 'preview') {
    const cols = dms_configuration.value.columns.map(c => columns_widths[c.id] ? { ...c, ...columns_widths[c.id] } : { ...c });

    dashboard_dms_store.update_dms_configuration({
      ...dms_configuration.value,
      ...{ columns: cols },
    });

    dashboard_store.set_widget_configuration({
      ...dms_configuration.value,
    });
  }
  else {
    const config = cloneDeep(props.data);

    const selected_cols = config.data.columns || dashboard_dms_store.standard_columns;

    const cols = selected_cols.map(c => columns_widths[c.id] ? { ...c, ...columns_widths[c.id] } : { ...c });
    config.data.columns = cols;

    dashboard_store.set_widget_uid(config.uid);
    dashboard_store.set_widget_name(config.data.name);
    dashboard_store.update_widget(config.data, false, $t);
  }
}

provide('display_filters_ref', '');
</script>

<template>
  <div>
    <div class="w-full h-full">
      <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
        <slot name="header-title" />
        <slot name="header-actions" />
      </div>
      <div v-if="state.is_table_loading">
        <HawkLoader />
      </div>
      <div v-else class="container flex 2xl:max-w-[100vw]">
        <div id="table_wrapper" class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
          <DocumentTable
            :key="forceUpdate"
            ref="document_table"
            :documents="document_store.documents"
            :refresh_documents="getData"
            :is_table_loading="state.is_table_loading"
            :is_widget="true"
            :options="{
              dropdown_items_ids: ['archive', 'delete'],
              disable_context_menu: true,
              hide_selection_checkbox: true,
              disable_custom_view_configuration: true,
              disable_header_context_menu_config: true,
              enable_pagination: true,
              widget_columns: props.data.data.columns || dashboard_dms_store.standard_columns,
            }"
            :can-resize="dashboard_store.is_editing_dashboard"
            @table-instance-created="updatePrintMap()"
            @column-resized="columnResized"
          />
        </div>
      </div>
    </div>
  </div>
</template>
