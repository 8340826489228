<!-- eslint-disable no-control-regex -->
<script setup>
import slugify from 'slugify';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const props = defineProps({
  field: {
    type: String,
    default: '',
  },
  prefix: {
    type: String,
    default: '',
  },
  isDropdown: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['copyClick']);

slugify.extend({
  '/': '-',
  '(': '',
  ')': '',
  '*': '',
  '&': '-',
  ':': '-',
  '\'': '',
  '"': '',
  '+': '',
  '@': '-',
  '!': '',
  '~': '',
});

const { copy } = useClipboard();

const dashboard_store = useDashboardStore();

const is_copied = ref(false);

const is_slug_mode_enabled = computed(() => dashboard_store.is_slug_mode);

function copyToClipboard(suffix = '') {
  const sluggified_fieldname = slugify(props.field.replaceAll(/[^\x00-\x7F]/g, '').replaceAll('.', '-'), { lower: true });
  const separator = props.prefix ? '.' : '';
  copy(`{{${props.prefix}${separator}${sluggified_fieldname}${suffix}}}`);
}

function onCopyClick(suffix = '') {
  is_copied.value = true;
  copyToClipboard(suffix);
  emits('copyClick');
}

const dropdown_items = [
  {
    label: '1/2',
    on_click: () => onCopyClick('-1_2'),
  },
  {
    label: '1/4',
    on_click: () => onCopyClick('-1_4'),
  },
  {
    label: '3/4',
    on_click: () => onCopyClick('-3_4'),
  },
  {
    label: '1/3',
    on_click: () => onCopyClick('-1_3'),
  },
  {
    label: '2/3',
    on_click: () => onCopyClick('-2_3'),
  },
];
</script>

<template>
  <hawk-menu
    v-if="isDropdown && is_slug_mode_enabled"
    additional_trigger_classes="!ring-0 hover:bg-gray-50"
    :items="dropdown_items"
    @click.stop=""
  >
    <template #trigger>
      <span v-click-outside="() => is_copied = false" class="text-success-600 text-xs flex items-center gap-1 cursor-pointer">
        <template v-if="is_copied">
          <IconHawkCheck class="inline-block h-4 w-4" />
          {{ $t('Copied') }}
        </template>
        <template v-else>
          <IconHawkCodeTwo v-tippy="$t('Copy')" class="inline-block text-primary-600 h-4 w-4" />
        </template>
      </span>
    </template>
  </hawk-menu>
  <template v-else>
    <span v-if="is_slug_mode_enabled" v-click-outside="() => is_copied = false" class="text-success-600 text-xs flex items-center gap-1 cursor-pointer" @click.prevent="() => onCopyClick()">
      <template v-if="is_copied">
        <IconHawkCheck class="inline-block h-4 w-4" />
        {{ $t('Copied') }}
      </template>
      <template v-else>
        <IconHawkCodeTwo v-tippy="$t('Copy')" class="inline-block text-primary-600 h-4 w-4" />
      </template>
    </span>
  </template>
</template>
