<script setup>
import { isEqual } from 'lodash-es';
import { watch } from 'vue';
import GraphComponent from '~/dashboard/components/widgets/graph-widgets/graph-component.vue';
import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  content_height: {
    type: Number,
  },
  activeSelectedRange: {
    type: Array,
    default: () => [],
  },
});

const $t = inject('$t');

const $services = inject('$services');

const dashboard_terra_store = useDashboardTerraStore();

const HEADER_KEY_MAP = {
  'total': $t('Total (scope)'),
  'actual': $t('Actual'),
  'remaining': $t('Remaining'),
  '%_actual': $t('% Actual'),
  '%_remaining': $t('% Remaining'),
  'daily_progress': $t('Today'),
  'this_week': $t('This week'),
  'this_month': $t('This month'),
  'this_year': $t('This year'),
  'dashboard_active_selected_range': $t('Selected range'),
};

const loading = ref(false);

const payload = ref(null);
const graph_data = ref(null);
const forceUpdate = ref(0);
const no_data = ref(false);

async function getReports() {
  graph_data.value = null;
  loading.value = true;
  payload.value = dashboard_terra_store.parse_terra_form_to_server_format(props.data.data);
  try {
    const { data } = await $services.terra_view_service.get_activities_table({ body: payload.value });

    if (Object.keys(data?.data)?.length) {
      no_data.value = false;
      if (props.data.data.transpose)
        graph_data.value = parseTransposeData(data.data);
      else
        graph_data.value = parseData(data.data);

      loading.value = false;
    }
    else {
      no_data.value = true;
      columns.value = [];
      activities.value = [];
      loading.value = false;
    }

    forceUpdate.value++;
  }
  catch (err) {
    loading.value = false;
    logger.log({ err });
  }
}

const field_name_map = computed(() => {
  const res = {};
  props.data.data?.workprogress_fields?.forEach((f) => {
    res[f.column] = f.name;
  });

  return res;
});

function getFieldName(key, value) {
  if (key === 'name')
    return 'Activity';

  const field_name = field_name_map.value[key] || 'Untitled';

  if (value.field_units)
    return `${field_name} (${value.field_units})`;

  return field_name;
}

const chart_type = computed(() => {
  return props.data?.data?.chart_type === 'horizontal_bar' ? 'msbar2d' : 'mscolumn2d';
});
function parseData(data) {
  const blocks = Object.values(data);
  const categories = [{ category: [] }];
  const datasetGroups = {};

  blocks.forEach((block) => {
    categories[0].category.push({ label: block.name });

    Object.entries(block).forEach(([key, value]) => {
      if (typeof value === 'object' && value.values) {
        Object.entries(value.values).forEach(([valKey, val]) => {
          const seriesName = value.name ? `${value.name} (${HEADER_KEY_MAP[valKey]})` : `${getFieldName(key, value)}(${HEADER_KEY_MAP[valKey]})`;

          if (!datasetGroups[value.name]) {
            datasetGroups[value.name] = [];
          }

          let series = datasetGroups[value.name].find(s => s.seriesname === seriesName);
          if (!series) {
            series = { seriesname: seriesName, data: [] };
            datasetGroups[value.name].push(series);
          }

          series.data.push({ value: val.toString() });
        });
      }
    });
  });

  let dataset = Object.values(datasetGroups).map(group => ({ dataset: group }));
  dataset = dataset.reduce((acc, item) => {
    item.dataset.forEach((data) => {
      acc.push(data);
    });
    return acc;
  }, []);
  return { categories, dataset };
}

function parseTransposeData(data) {
  const blocks = Object.values(data);
  const categories = [{ category: [] }];
  const datasetGroups = {};

  const metricNames = new Set();
  blocks.forEach((block) => {
    Object.entries(block).forEach(([key, value]) => {
      if (typeof value === 'object' && value.values) {
        value.name ? metricNames.add(value.name) : metricNames.add(getFieldName(key, value));
      }
    });
  });

  metricNames.forEach((metric) => {
    categories[0].category.push({ label: metric });
  });

  blocks.forEach((block) => {
    const blockDataset = [];

    // eslint-disable-next-line unused-imports/no-unused-vars
    Object.entries(block).forEach(([key, value]) => {
      if (typeof value === 'object' && value.values) {
        Object.entries(value.values).forEach(([valKey, val]) => {
          const seriesName = `${block.name} (${HEADER_KEY_MAP[valKey]})`;

          let seriesData = blockDataset.find(s => s.seriesname === seriesName);
          if (!seriesData) {
            seriesData = { seriesname: seriesName, data: [] };
            blockDataset.push(seriesData);
          }

          const metricIndex = categories[0].category.findIndex(c => c.label === value.name);

          seriesData.data[metricIndex] = { value: val.toString() };
        });
      }
    });

    blockDataset.forEach((dataset) => {
      for (let i = 0; i < categories[0].category.length; i++) {
        if (!dataset.data[i]) {
          dataset.data[i] = { value: '0' }; // Fill missing values with 0
        }
      }
    });

    datasetGroups[block.name] = { dataset: blockDataset };
  });

  let dataset = Object.values(datasetGroups);
  dataset = dataset.reduce((acc, item) => {
    item.dataset.forEach((data) => {
      acc.push(data);
    });
    return acc;
  }, []);

  return {
    categories,
    dataset,
  };
}

function toolText() {
  return '<div><table id=\'dataTable\' ><tr class=\'\'></tr><tr><td style="font-weight:700">$seriesname:</td><td style="font-weight:200">$value</td></tr></table></div>';
}
const graph_config = computed(() => ({
  chart_name: props.data.data.name,
  dimensions: {
    x: props.data.x,
    y: props.data.y,
    h: props.data.h,
  },
  renderAt: `chart-${props.id}`,
  type: chart_type.value,
  dataSource: {
    chart: {
      xAxisName: props.data.data.transpose ? 'Fields' : 'Layers',
      yAxisName: props.data.data.transpose ? 'Layers' : 'Fields',
      plotToolText: toolText(),
      showAlternateHGridColor: 0,
      showAlternateVGridColor: 0,
      showValues: 1,

    },
    dashboard_index: props.data.i,
    chart_type: props.data.data.chart_type,
    ...graph_data.value,
  },
}));

watch(() => props.data.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    await getReports();
  }
}, { immediate: true, deep: true });
</script>

<template>
  <div class="w-full h-full">
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>

    <hawk-loader v-if="loading" />

    <GraphComponent v-else-if="graph_config?.renderAt" :id="props.id" :configuration="graph_config" :handle_print="true" />
  </div>
</template>
