import { escape } from 'lodash-es';
import { $date } from '~/common/utils/date.util';
import { createCombinedTable } from '~/dashboard/print/charts/combined-table.js';
import { createSingleFormView } from '~/dashboard/print/charts/form-single-view.js';
import { createFormsList } from '~/dashboard/print/charts/forms-list.js';
import { createGraph } from '~/dashboard/print/charts/graph.js';
import { createIndexChart } from '~/dashboard/print/charts/index-chart.js';
import { createNumberChart } from '~/dashboard/print/charts/number-chart.js';
import { createPivotTable } from '~/dashboard/print/charts/pivot-table.js';
import { createProgressCardView } from '~/dashboard/print/charts/progress-card.js';
import { createRichText } from '~/dashboard/print/charts/rich-text.js';
import { createTableWidget } from '~/dashboard/print/charts/table.js';
import { createTaskList } from '~/dashboard/print/charts/task-list.js';
import { createTerraMapView } from '~/dashboard/print/charts/terra-map-view.js';
import { createTransmittalDetailsView } from '~/dashboard/print/charts/transmittal-details.js';
import print_config from '~/dashboard/print/print_config';
import { encodeBase64 } from '~/dashboard/print/utilities.js';

const {
  css_stylesheet,
  prose_mirror_editor_styling,
  template_meta_data,
  taskmapper_logo,
} = print_config;

function getTemplateHeaderImage(meta_data) {
  const pdf_report_logo_width = meta_data.organization_image?.size?.[0] || 80;

  const default_width = 80;
  const default_height = 30;
  const rendered_width = pdf_report_logo_width || default_width;

  const size_ratio = rendered_width / default_width;

  const image_width = `${rendered_width}px`;
  const image_height = `${Math.ceil(default_height * size_ratio)}px`;

  let header_image = taskmapper_logo;

  if (meta_data.organization_image?.url)
    header_image = `<img src="${meta_data.organization_image.url}"></img>`;

  return `
    <div class="dashboard-intro__logo" style="width: ${image_width}; height: ${image_height}">
      ${header_image}
    </div>
  `;
};

function createTemplateHeader(meta_data) {
  const header_image = getTemplateHeaderImage(meta_data);

  let meta_details = '';

  if (meta_data.dashboard_scope === 'global') {
    meta_details = `
      <div class="dashboard-intro__meta">
        <span>Asset:</span> ${escape(meta_data.asset_name)}
        <br>
        ${escape(meta_data.asset_address)}
      </div>
    `;
  }
  else if (meta_data.dashboard_scope === 'forms') {
    meta_details = `
      <div class="dashboard-intro__meta">
        <span>Template:</span> ${escape(meta_data.template_name)}
      </div>
    `;
  }

  return `
      <div class="dashboard-header">
      <div class="dashboard-intro">
        ${header_image}
        <div class="dashboard-intro__content">
          <h3>
            ${escape(meta_data.dashboard_name)}
          </h3>
          ${meta_details}
        </div>
      </div>
        <div class='date-text'>
         <p>
           Generated by: ${meta_data.created_by}
         </p>
         <p>
          Generated on: ${$date(new Date(), 'DATETIME_LONG_WITH_OFFSET')}
         </p>
        </div>
        </div>
  `;
}

function getPdfTitle(meta_data) {
  const date = new Date();
  const date_string = $date(
    date.toISOString(),
    'DATETIME_MED',
    false,
  );

  if (meta_data.dashboard_scope === 'organization')
    return `${meta_data.organization_name} - ${meta_data.dashboard_name} - ${date_string}.pdf`;
  if (meta_data.dashboard_scope === 'forms')
    return `${meta_data.template_name} - ${meta_data.dashboard_name} - ${date_string}.pdf`;
  else
    return `${meta_data.asset_name} - ${meta_data.dashboard_name} - ${date_string}.pdf`;
};

async function downloadPdf(chart_data, template, meta_data) {
  const encoded_template = await encodeBase64(template);

  const result = await fetch(`${import.meta.env.VITE_APP_API_HOST}/dashboard-exporter/v1/resource/export/`, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      chartData: chart_data,
      template: encoded_template,
    }),
  });
  const blob = await result.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = getPdfTitle(meta_data);
  document.body.appendChild(a);
  a.click();
  a.remove();
}

function sortChartsCriteria(a, b) {
  if (a.dimensions.y < b.dimensions.y)
    return -1;
  else if (a.dimensions.y > b.dimensions.y)
    return 1;
  else
    if (a.dimensions.x < b.dimensions.x)
      return -1;
    else if (a.dimensions.x > b.dimensions.x)
      return 1;
    else return 0;
}

function createWidget(chart, index) {
  let chart_container = null;

  switch (chart.renderType) {
    case 'fusion-chart':
      chart_container = createGraph(chart, index);
      break;
    case 'rich-text':
      chart_container = createRichText(chart, index);
      break;
    case 'table':
      chart_container = createTableWidget(chart, index);
      break;
    case 'forms-list':
      chart_container = createFormsList(chart, index);
      break;
    case 'tasks-list':
      chart_container = createTaskList(chart, index);
      break;
    case 'index-chart':
      chart_container = createIndexChart(chart, index);
      break;
    case 'number-chart':
      chart_container = createNumberChart(chart, index);
      break;
    case 'terra-map':
      chart_container = createTerraMapView(chart, index);
      break;
    case 'single_form_view':
      chart_container = createSingleFormView(chart, index);
      break;
    case 'transmittal_details':
      chart_container = createTransmittalDetailsView(chart, index);
      break;
    case 'progress-card':
      chart_container = createProgressCardView(chart, index);
      break;
    case 'combined_table_graph':
      chart_container = createCombinedTable(chart, index);
      break;
    case 'pivot_table':
      chart_container = createPivotTable(chart, index);
  }

  const empty_element = document.createElement('div');

  return chart_container || empty_element;
}

async function generateTemplate(chart_configs = null, meta_data = {}) {
  if (!chart_configs || !Object.keys(chart_configs).length)
    return;
  const template = document.implementation.createHTMLDocument('PDF Export');
  const style_tag = document.createElement('style');
  const prose_style_tag = document.createElement('style');
  style_tag.innerHTML = css_stylesheet;
  prose_style_tag.innerHTML = prose_mirror_editor_styling;

  template.head.innerHTML = template_meta_data;
  template.head.appendChild(style_tag);
  template.head.appendChild(prose_style_tag);
  template.body.classList.add('dashboard-container');
  template.body.innerHTML = createTemplateHeader(meta_data);

  const chart_data = [];

  const sorted_charts = Object.values(chart_configs).sort(sortChartsCriteria);

  sorted_charts.forEach((chart, index) => {
    const chart_container = createWidget(chart, index);
    template.body.appendChild(chart_container);

    if (chart?.renderType === 'fusion-chart')
      chart_data.push(chart);
    if (chart?.renderType === 'combined_table_graph')
      chart_data.push(chart.dataSource.graph_config);
  });

  // we can't convert a dom element to base64, we need to make it a string
  const container = `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        ${template.head.innerHTML}
      </head>

      <body class="dashboard-container">
        ${template.body.innerHTML}
      </body>
      <footer>
        <div class=text-end>
          ${$date(new Date(), 'DATETIME_LONG_WITH_OFFSET')}
        </div>
      </footer>
    </html>
  `;
  await downloadPdf(chart_data, container, meta_data);

  return true;
}

export { generateTemplate };
