<script setup>
import { MenuItem } from '@headlessui/vue';
import { $date } from '~/common/utils/date.util';

const props = defineProps({
  activeRange: {
    type: Object,
    default: () => {},
  },
  dateFilters: {
    type: Array,
    default: () => [],
  },
  widthClass: {
    type: String,
    default: 'w-[350px]',
  },
  position: {
    type: String,
    default: 'bottom',
  },
});

const emit = defineEmits(['filterApplied']);

const form_data = ref(null);

const show_dropdown = ref(false);

const date_range = ref(null);
const custom_date_range = ref(null);

const active_date_range = computed(() => {
  if (date_range.value?.value === 'custom')
    return custom_date_range.value;
  else if (date_range.value?.get_value)
    return date_range.value.get_value();
  return [];
});

function handleDateRangeSelection(e) {
  custom_date_range.value = e?.value === 'custom' ? (form_data.value?.date_range || props.activeRange.active_range) : null;
  date_range.value = e;

  const payload = { value: e?.value, active_range: active_date_range.value };
  emit('filterApplied', payload);
}

watch(() => props.activeRange?.value, (newVal) => {
  date_range.value = props.dateFilters.find(filter => filter.value === newVal);
  if (date_range.value?.value === 'custom')
    custom_date_range.value = props.activeRange.active_range;
});

onMounted(() => {
  if (props.activeRange?.value) {
    const date_range = props.dateFilters.find(filter => filter.value === props.activeRange.value);
    handleDateRangeSelection(date_range);
  }
});
</script>

<template>
  <hawk-menu
    :position="position"
    additional_trigger_classes="!ring-0 w-full"
    @open="show_dropdown = true"
    @close="show_dropdown = false"
  >
    <template #trigger>
      <div class="grid grid-cols-12 border border-gray-300 rounded-lg text-sm" :class="widthClass">
        <div class="col-span-2 py-2">
          {{ date_range?.prefix || 'A' }}
        </div>
        <div class="col-span-9 border-l border-gray-300 py-2">
          <p v-if="active_date_range?.length === 2">
            {{ $date(active_date_range[0], 'MMM DD, hh:mm A') }} - {{ $date(active_date_range[1], 'MMM DD, hh:mm A') }}
          </p>
          <p v-else>
            {{ $t('All time') }}
          </p>
        </div>
        <div class="col-span-1 py-2 flex items-center">
          <IconHawkChevronDown
            class="transition-transform h-4 text-gray-600"
            :class="{ 'rotate-180 !visible': show_dropdown }"
          />
        </div>
      </div>
    </template>
    <template #content="{ close }">
      <div>
        <MenuItem
          v-for="date_filter in dateFilters"
          v-slot="{ active }"
          :key="date_filter.value"
          as="div"
          class="px-2"
          :class="widthClass"
        >
          <hawk-menu
            v-if="date_filter.value === 'custom'"
            additional_dropdown_classes="right-full !top-0 mr-1 !mt-0 !bottom-auto"
            additional_trigger_classes="!ring-0 w-full"
            position="bottom-left"
          >
            <template #trigger>
              <div class="p-3 rounded-md text-sm text-gray-700 font-medium hover:bg-gray-50">
                <div class="grid grid-cols-12">
                  <div class="col-span-2 text-left">
                    {{ date_filter.prefix }}
                  </div>
                  <div>
                    {{ date_filter.label }}
                  </div>
                </div>
              </div>
            </template>
            <template #content>
              <div class="p-3 w-[390px]" @click.stop>
                <Vueform v-model="form_data" sync size="sm">
                  <div class="col col-span-12">
                    <div class="font-medium text-sm mb-2">
                      {{ $t('Date Range') }}
                    </div>
                    <DateTimeElement
                      name="date_range"
                      class="w-full"
                      :options="{
                        placeholder: 'Select date range',
                        clearable: false,
                        enableTimePicker: true,
                        range: true,
                        teleport: false,
                        format: 'MMM dd, hh:mm aa',
                      }"
                    />
                  </div>
                </Vueform>
              </div>
            </template>
            <template #footer="{ close: closeDateRangePicker }">
              <Vueform size="sm" class="p-2">
                <div class="flex justify-end w-full col-span-full">
                  <ButtonElement
                    name="cancel"
                    :secondary="true"
                    @click="close()"
                  >
                    {{ $t('Cancel') }}
                  </ButtonElement>
                  <ButtonElement
                    name="apply"
                    class="ml-3"
                    :disabled="!form_data?.date_range?.length"
                    @click="handleDateRangeSelection(date_filter);closeDateRangePicker();close();"
                  >
                    {{ $t('Apply') }}
                  </ButtonElement>
                </div>
              </Vueform>
            </template>
          </hawk-menu>
          <div
            v-else
            :class="{ 'bg-gray-50': active, 'bg-gray-100': date_filter.value === date_range?.value }"
            class="flex items-center justify-between whitespace-nowrap cursor-pointer p-3 rounded-md text-sm text-gray-700 font-medium"
            @click="handleDateRangeSelection(date_filter)"
          >
            <div class="grid grid-cols-12 text-sm font-medium">
              <div class="col-span-2">
                {{ date_filter.prefix }}
              </div>
              <div class="whitespace-nowrap">
                {{ date_filter.label }}
              </div>
            </div>
          </div>
        </MenuItem>
      </div>
    </template>
  </hawk-menu>
</template>
