<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { isEqual, toString, uniqBy } from 'lodash-es';
import { watch } from 'vue';
import HawkHandsontable from '~/common/components/organisms/hawk-handsontable/hawk-handsontable.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useTagsStore } from '~/common/stores/tags.store';
import { convertTableUnitsData } from '~/common/utils/common.utils.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
  response_data: {
    type: Object,
  },
  payload: {
    type: Object,
  },
  graph_config: {},
});
const { common_store } = useCommonImports();
const tags_store = useTagsStore();
const loading = ref(false);
const activities = ref([]);
const columns = ref([]);
const dashboard_store = useDashboardStore();
const activity_key_map = ref(null);
const no_data = ref(false);
const prevent_watcher = ref(false);
const columns_ref = ref('');
const hotTable = ref(null);
const handsontable_map = ref({});
const nested_headers = ref([]);

const column_key_map = {
  actual: '% Actual',
  actual_units: 'Actual',
  lag: '% Lag',
  lag_units: 'Lag',
  planned: '% Planned',
  planned_units: 'Planned',
  remaining: '% Remaining',
  remaining_units: 'Remaining',
  total_units: 'Total (Scope)',
};

function hotSettings() {
  return {
    rowHeaders: true,
    rowHeights: 26,
    viewPortRowRenderingOffset: 100,
    viewportColumnRenderingOffset: 40,
    dropdownMenu: false,
    contextMenu: false,
    filters: false,
    manualColumnResize: props.id === 'preview' || dashboard_store.is_editing_dashboard,
    manualRowMove: false,
    manualColumnMove: false,
    readOnly: true,
  };
}

function cells(row, col) {
  const value = this.instance.getDataAtCell(row, col);
  const { fileUploadRenderer } = hotTable.value?.getCustomFunctions();
  const cellProperties = {};
  if (isUrl(value?.[0]))
    cellProperties.renderer = fileUploadRenderer;
  else
    cellProperties.renderer = 'html';
  return cellProperties;
}

const colHeaders = function (index) {
  return columns.value[index].text;
};

async function getReports() {
  loading.value = true;
  activity_key_map.value = props.payload.properties.activity_table_activities;
  try {
    const data = props.response_data;
    if (data && Object.keys(data)) {
      generateActivities(data.data);
      loading.value = false;
    }
    else {
      no_data.value = true;
      loading.value = false;
    }
  }
  catch (err) {
    logger.log({ err });
    loading.value = false;
  }
}

function replace(string) {
  return string.split('.').join('-');
}

const columns_widths_map = computed(() => props.data.data.columns_widths || {});

function getNestedColumns(columns) {
  const res = columns.reduce((acc, col) => {
    col.column_types?.forEach((sub_col) => {
      if (!acc.includes(sub_col))
        acc.push(sub_col);
    });

    return acc;
  }, []);

  return res;
}

function fetchDataValues(dataItem, type) {
  if (type === 'resources') {
    if (dataItem?.[0]?.type === 'member') {
      const user = common_store.users.find(a => a.uid === dataItem);
      return user ? `${user.first_name || ''} ${user.last_name || ''}`.trim() || user.email : null;
    }
    else if (dataItem?.[0]?.type === 'custom') {
      return dataItem?.[0]?.name;
    }
    else if (type === 'priority') {
      const priority_values = {
        1: 'Critical',
        2: 'High',
        3: 'Medium',
        4: 'Low',
        5: 'Not set',
      };
      return priority_values[id];
    }
    else {
      return '_';
    }
  }
  if (type === 'category') {
    return common_store.get_category(dataItem)?.name || '_';
  }
  if (type === 'tags')
    return tags_store.get_tag(dataItem)?.name || '_';
  return '_';
}

function generateActivities(data) {
  const first_level = [{ label: '', colspan: 1 }];
  const second_level = [];
  const nested_column_list = getNestedColumns(props.payload.data.columns);

  const column_array = [{
    id: 'name',
    text: 'Field',
    header: 'Field',
    width: columns_widths_map.value.name || '400',
    size: columns_widths_map.value.name || '400',
    data: 'name',
  }];

  Object.values(data).forEach((value) => {
    first_level.push({ label: value.name, colspan: nested_column_list.length });
    const current_column = {
      id: replace(value.id),
      data: replace(value.id),
      readOnly: true,
      text: value === 'name' ? 'Activity' : (activity_key_map.value.find(e => e.id === value.id)?.name || value.name),
      header: value === 'name' ? 'Activity' : (activity_key_map.value.find(e => e.id === value.id)?.name || value.name),
      has_nested_column: false,
    };

    // eslint-disable-next-line unused-imports/no-unused-vars
    for (const [nestedKey, nestedValue] of Object.entries(value)) {
      if (nestedValue?.values) {
        current_column.has_nested_column = true;
        column_array.push(...nested_column_list.map((nestedCol) => {
          const col_id = replace(`${nestedCol}-${value.id}`);
          return {
            id: col_id,
            width: columns_widths_map.value?.[col_id] || '150',
            size: columns_widths_map.value?.[col_id] || '150',
            data: col_id,
            readOnly: true,
            text: nestedCol === 'id' ? 'Activity id' : column_key_map[nestedCol],
            header: nestedCol === 'id' ? 'Activity id' : column_key_map[nestedCol],
          };
        }));
      }
    }
    if (!nested_column_list.length || !current_column.has_nested_column)
      column_array.push(current_column);
  });
  // Activities
  const fields = props.data.data.columns.reduce((acc, columnData) => {
    acc.list.push({
      fieldKey: columnData.key,
      fieldName: columnData.name,
    });
    return acc;
  }, {
    list: [],
  });
  const activity_array_better = fields.list.reduce((acc, { fieldKey, fieldName }) => {
    const fieldData = {};
    Object.keys(data).forEach((dataKey) => {
      const dataItem = data[dataKey][fieldKey];
      fieldData.name = dataItem?.units ? `${fieldName} (${dataItem.units})` : fieldName;

      if (['category', 'resources', 'tags', 'priority'].includes(fieldKey)) {
        fieldData[replace(data[dataKey].id)] = fetchDataValues(dataItem, fieldKey);
      }
      else if (dataItem && typeof dataItem === 'object') {
        Object.keys(dataItem?.values ?? {}).forEach((valueKey) => {
          (fieldData[replace(`${valueKey}-${data[dataKey].id}`)]) = convertTableUnitsData(dataItem?.values[valueKey], dataItem.units);
        });
      }
      else if (nested_column_list.length) {
        const first_subcol_key = nested_column_list[0];

        fieldData[replace(`${first_subcol_key}-${data[dataKey].id}`)] = convertTableUnitsData(dataItem, dataItem?.units);
      }

      else { fieldData[replace(data[dataKey].id)] = convertTableUnitsData(dataItem, null); }
    });
    acc.push(fieldData);
    return acc;
  }, []);

  activities.value = activity_array_better;
  columns.value = uniqBy(column_array, c => c.id);
  columns.value.forEach(column => second_level.push({ label: column.text, colspan: 1 }));
  nested_headers.value = nested_column_list.length ? [first_level, second_level] : [];
}

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: props.data.data.activity_table_chart ? 'combined_table_graph' : 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: columns.value,
      activities: activities.value,
      dataset: activities.value,
      is_transpose: props.data.data.transpose === true,
      dashboard_index: props.data.i,
      is_new_pivot_chart: props.data.data.chart === 'workflow_pivot_table',
      ...(props.data.data.activity_table_chart ? { graph_config: props.graph_config } : {}),
    },
  });
  dashboard_store.update_new_print_map((props.data?.data?.name || 'untitled'), {
    widget_type: 'table',
    type: props.data?.data?.type,
    dataSource: parseNewPrintData(activities.value),
  });
}

function parseNewPrintData(data) {
  const accessor_keys = columns.value.map(c => c.accessorKey);
  const parsed_data = [];
  data.forEach((d) => {
    parsed_data.push(accessor_keys.map(k => toString(d[k])));
  });
  return parsed_data;
}

function columnResized(_resized_column, columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}

watch(() => props.data.data, async (new_val, old_val) => {
  // subcolumns are too deeply nested, this fixes it
  const new_columns_ref = JSON.stringify(new_val?.columns || {});

  if (new_val && (
    !isEqual(new_val, old_val)
    || new_columns_ref !== columns_ref.value
  )) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getReports();
    columns_ref.value = new_columns_ref;
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true, deep: true });

watch(() => dashboard_store.is_editing_dashboard, async (new_val) => {
  if (handsontable_map.value[props.id]) {
    handsontable_map.value[props.id].updateSettings({
      manualColumnResize: new_val,
    });
  }
}, { immediate: true });
</script>

<template>
  <div>
    <div v-if="no_data" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <hawk-loader v-if="loading" />
    <a v-if="activities?.length && !loading">
      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <HawkHandsontable
          ref="hotTable"
          :apply-read-only-class="false"
          :data="activities"
          :columns="columns"
          :hot-settings="hotSettings()"
          :add-new-row-on-enter="false"
          :hot-table-id="id"
          :col-headers="colHeaders"
          :nested-headers="nested_headers.length ? nested_headers : null"
          :cells="use_custom_cells ? cells : null"
          :height="id !== 'preview' ? '100%' : '450px'"
          @ready="handsontable_map[id] = $event"
          @after-columns-resized="columnResized"
        />
      </div>
    </a>
  </div>
</template>
