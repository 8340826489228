<!-- eslint-disable vue/prop-name-casing -->
<script setup>
defineProps({
  selected_items: {
    type: Array,
    default: () => [],
  },
  bulk_action_options: {
    type: Array,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  disabled_tooltip: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['clear']);
</script>

<template>
  <div class="w-full flex items-center justify-between">
    <div class="flex items-center">
      <hawk-menu v-tippy="{ content: disabled ? disabled_tooltip : '' }" :items="bulk_action_options" :disabled="disabled">
        <template #trigger>
          <hawk-button type="outlined">
            {{ $t('Actions') }}
            <icon-hawk-chevron-down class="text-gray-600" />
          </hawk-button>
        </template>
      </hawk-menu>
      <p class="text-gray-700 text-sm mx-4">
        {{ `${selected_items.length} ${$t('selected')}` }}
      </p>
      <p
        class="font-semibold text-sm text-gray-600 cursor-pointer"
        @click="emit('clear')"
      >
        {{ $t('Clear') }}
      </p>
    </div>
    <div>
      <slot name="right" />
    </div>
  </div>
</template>
