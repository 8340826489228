<script setup>
import { isEqual, toString } from 'lodash-es';
import { watch } from 'vue';

import HawkHandsontable from '~/common/components/organisms/hawk-handsontable/hawk-handsontable.vue';

import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardTerraStore } from '~/dashboard/store/dashboard-terra.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  content_height: {
    type: Number,
  },
});

const $services = inject('$services');

const dashboard_terra_store = useDashboardTerraStore();
const dashboard_store = useDashboardStore();

const loading = ref(false);

const state = reactive({
  table_dataset: [],
  table_columns: [],
  payload: null,
  no_data: false,
  prevent_watcher: false,
});
const force_render = ref(0);
const handsontable_map = ref({});

const columns_widths_map = computed(() => props.data.data.columns_widths || {});
const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

async function getReports() {
  loading.value = true;
  state.payload = dashboard_terra_store.parse_terra_form_to_server_format(props.data.data);
  try {
    const { data } = await $services.terra_view_service.get_graph({ body: state.payload });

    if (data?.length) {
      const is_transpose = props.data?.data?.transpose;
      const is_grouped = props.data?.data?.feature_group !== 'none';

      if (is_grouped)
        is_transpose ? generateGroupedTransposeTable(data) : generateGroupedTable(data);
      else
        is_transpose ? generateTransposedTable(data) : generateTable(data);

      state.no_data = false;
      loading.value = false;
      force_render.value += 1;
    }
    else {
      state.no_data = true;
      state.table_dataset = [];
      state.table_columns = [];
      loading.value = false;
    }
  }
  catch {
    loading.value = false;
  }
}

function generateTable(data) {
  const columns = [
    {
      id: 'feature_type',
      data: 'feature_type',
      width: columns_widths_map.value.feature_type || 250,
      size: columns_widths_map.value.feature_type || 250,
      text: 'Class',
      header: 'Class',
    },
    {
      id: 'count',
      data: 'count',
      width: columns_widths_map.value.count || 250,
      size: columns_widths_map.value.count || 250,
      text: 'Count',
      header: 'Count',
    },
  ];

  const dataset = data.map(item => ({
    feature_type: item.featureType_name,
    count: item.count,
  }));

  state.table_columns = [...columns];
  state.table_dataset = [...dataset];
}

function generateTransposedTable(data) {
  const columns = data.map(item => ({
    id: item.featureType_uid,
    data: item.featureType_uid,
    width: columns_widths_map.value[item.featureType_uid] || 150,
    size: columns_widths_map.value[item.featureType_uid] || 150,
    text: item.featureType_name,
    header: item.featureType_name,
  }));

  const dataset = {};
  data.forEach((item) => {
    dataset[item.featureType_uid] = item.count;
  });

  state.table_columns = [...columns];
  state.table_dataset = [dataset];
}

function generateGroupedTable(data) {
  const columns = [
    {
      id: 'feature_type',
      data: 'feature_type',
      width: columns_widths_map.value.feature_type || 250,
      size: columns_widths_map.value.feature_type || 250,
      text: 'Class',
      header: 'Class',
    },
  ];

  const used_columns_keys = [];

  const dataset = {};
  data.forEach((item) => {
    const column_key = String(item.key);
    if (!used_columns_keys.includes(column_key)) {
      columns.push({
        id: column_key,
        width: columns_widths_map.value[column_key] || 150,
        size: columns_widths_map.value[column_key] || 150,
        text: column_key,
        header: column_key,
        custom_renderer: true,
        renderer: (_instance, td, row, _col, _prop, _value) => {
          const data = state.table_dataset[row];
          td.textContent = data[column_key];
        },
      });

      used_columns_keys.push(column_key);
    }
  });

  data.forEach((item) => {
    if (!dataset[item.featureType_uid]) {
      dataset[item.featureType_uid] = {
        feature_type: item.featureType_name,
      };
    }

    const column_key = String(item.key);
    dataset[item.featureType_uid][column_key] = item.count;
  });

  state.table_columns = [...columns];
  state.table_dataset = Object.values(dataset || {});
}

function generateGroupedTransposeTable(data) {
  let first_column_header = null;
  if (props.data?.data?.feature_group === 'extraProperties')
    first_column_header = 'Property';
  else if (props.data?.data?.feature_group === 'project')
    first_column_header = 'Project';

  const columns = [
    {
      id: 'group',
      data: 'group',
      width: columns_widths_map.value.group || 250,
      size: columns_widths_map.value.group || 250,
      text: first_column_header,
      header: first_column_header,
    },
  ];
  const dataset = {};
  const used_columns_keys = [];

  data.forEach((item) => {
    if (!used_columns_keys.includes(item.featureType_uid)) {
      columns.push({
        id: item.featureType_uid,
        width: columns_widths_map.value[item.featureType_uid] || 150,
        size: columns_widths_map.value[item.featureType_uid] || 150,
        text: item.featureType_name,
        header: item.featureType_name,
        custom_renderer: true,
        renderer: (_instance, td, row, _col, _prop, _value) => {
          const data = state.table_dataset[row];
          td.textContent = data[item.featureType_uid];
        },
      });

      used_columns_keys.push(item.featureType_uid);
    }
  });

  data.forEach((item) => {
    const row_key = String(item.key);
    if (!dataset[row_key]) {
      dataset[row_key] = {
        group: row_key,
      };
    }

    dataset[row_key][item.featureType_uid] = item.count;
  });

  state.table_columns = [...columns];
  state.table_dataset = Object.values(dataset || {});
}

function columnResized(columns_widths) {
  state.prevent_watcher = true;
  const columns_widths_by_key = state.table_columns.reduce((acc, col, idx) => {
    acc[col.data || col.id] = { size: columns_widths[idx], id: col.data || col.id };
    return acc;
  }, {});
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths_by_key,
  );
}

function hotSettings() {
  return {
    rowHeaders: true,
    rowHeights: 26,
    viewPortRowRenderingOffset: 100,
    viewportColumnRenderingOffset: 40,
    dropdownMenu: false,
    contextMenu: false,
    filters: false,
    manualColumnResize: props.id === 'preview' || dashboard_store.is_editing_dashboard,
    manualRowMove: false,
    manualColumnMove: false,
    readOnly: true,
  };
}

const colHeaders = function (index) {
  return state.table_columns[index].text;
};

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: state.table_columns,
      activities: state.table_dataset,
      dataset: state.table_dataset,
      is_transpose: false,
      dashboard_index: props.data.i,
    },
  });
  dashboard_store.update_new_print_map((props.data?.data?.name || 'untitled'), {
    widget_type: 'table',
    type: props.data?.data?.type,
    dataSource: parseNewPrintData(state.table_dataset),
  });
}

function parseNewPrintData(data) {
  const accessor_keys = state.table_columns.map(c => c.data);
  const parsed_data = [];
  data.forEach((d) => {
    parsed_data.push(accessor_keys.map(k => toString(d[k])));
  });
  return parsed_data;
}

watch(() => props.data.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    if (state.prevent_watcher) {
      state.prevent_watcher = false;
      return;
    }
    await getReports();
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true, deep: true });

watch(() => dashboard_store.is_editing_dashboard, async (new_val) => {
  if (handsontable_map.value[props.id]) {
    handsontable_map.value[props.id].updateSettings({
      manualColumnResize: new_val,
    });
  }
}, { immediate: true });
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <div v-if="state.no_data" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <hawk-loader v-if="loading" />
    <a v-else-if="state.table_dataset?.length && state.table_columns?.length">
      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <HawkHandsontable
          :apply-read-only-class="false"
          :data="state.table_dataset"
          :columns="state.table_columns"
          :hot-settings="hotSettings()"
          :add-new-row-on-enter="false"
          :hot-table-id="id"
          :col-headers="colHeaders"
          :height="`${content_height || height}px`"
          @ready="handsontable_map[id] = $event"
          @after-columns-resized="columnResized"
        />
      </div>
    </a>
  </div>
</template>
