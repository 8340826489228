<!-- eslint-disable unused-imports/no-unused-vars -->
<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { cloneDeep, isEqual, toString } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';

import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  content_height: {
    type: Number,
  },
  activeSelectedRange: {
    type: Array,
    default: () => [],
  },
});

const dashboard_selected_range = inject('dashboardSelectedRange');

const router = useRouter();
const route = useRoute();
const $date = inject('$date');
const $services = inject('$services');
const $t = inject('$t');
const loading = ref(false);
const activities = ref([]);
const columns = ref([]);
const graph_data = ref(null);
const payload = ref(null);
const dashboard_forms_store = useDashboardFormsStore();
const dashboard_store = useDashboardStore();
const forceUpdate = ref(0);
const no_data = ref(false);
const prevent_watcher = ref(false);
const form_error = ref(false);
const hotTable = ref(null);
const handsontable_map = ref({});

const { parseRulesDateData } = useFamConstants();
function hotSettings() {
  return {
    rowHeaders: true,
    rowHeights: 26,
    viewPortRowRenderingOffset: 100,
    viewportColumnRenderingOffset: 40,
    dropdownMenu: false,
    contextMenu: false,
    filters: false,
    manualColumnResize: props.id === 'preview' || dashboard_store.is_editing_dashboard,
    manualRowMove: false,
    manualColumnMove: false,
    readOnly: true,
  };
}
const {
  forms_v2_filters,
} = storeToRefs(dashboard_forms_store);

const attachment_uids = ref([]);

const use_custom_cells = computed(() => {
  const field_types = payload.value.fields.map(f => f.type);
  return props.data?.data?.transpose && (field_types.includes('attachment') || field_types.includes('long_text'));
});

function formatReponseTableData(data) {
  if (!data.value) {
    return '-';
  }
  else if (data.type === 'long_text') {
    return data.value;
  }

  else if (data.type === 'object' && data.uid === 'attached_to') {
    return data.value?.form_reference_name || data.value?.name || '-';
  }
  else if (data.type === 'attachment') {
    return data.value.map(v => v.thumbnails.small).filter(v => v) || '-';
  }

  else if (
    (data.type === 'date' && data.uid === 'submitted_at')
    || data.type === 'datetime'
  ) { return $date(data.value, 'DATETIME_MED'); }

  else if (data.type === 'date') {
    return $date(data.value, 'DATE_MED');
  }

  else if (data.type === 'time') {
    return $date(data.value, 'TIME_24_SHORT');
  }
  else { return data.value; }
}
async function getReports() {
  no_data.value = false;
  graph_data.value = null;
  loading.value = true;
  payload.value = dashboard_forms_store.parse_forms_form_to_server_format(props.data.data);
  const forms_payload = cloneDeep(payload.value);

  const filters = forms_payload.filters.advanced_filter && Object.keys(forms_payload.filters.advanced_filter).length ? [forms_payload.filters.advanced_filter, ...cloneDeep(forms_v2_filters.value) || []] : cloneDeep(forms_v2_filters.value);

  forms_payload.filters = { ...forms_payload.filters, advanced_filter: filters };
  forms_payload.filters.asset_uid = route.params.asset_id || null;
  forms_payload.filters.advanced_filter = forms_payload.filters.advanced_filter.map((filter) => {
    filter.rules = parseRulesDateData(filter.rules);
    return filter;
  });
  try {
    const { data } = await $services.forms.get_graph({ body: forms_payload });
    if (data?.data?.length) {
      if (props.data.data.transpose)
        generateTransposeActivities(data.data);
      else
        generateActivities(data.data);
      no_data.value = false;
    }
    else {
      no_data.value = true;
      columns.value = [];
      activities.value = [];
    }

    forceUpdate.value++;
    form_error.value = null;
    loading.value = false;
  }
  catch (err) {
    form_error.value = err?.response?.status === 400 ? $t('Template is not published') : $t('Template not found');
    loading.value = false;
  }
}

const columns_widths_map = computed(() => props.data.data.columns_widths || {});

function generateTransposeActivities(data) {
  const column_array = [{
    id: 'name',
    width: columns_widths_map.value.name || 400,
    size: columns_widths_map.value.name || 400,
    data: 'name',
    readOnly: true,
    text: 'Field name',
    header: 'Field name',
  }];

  attachment_uids.value = [];

  data.reduce((acc, item) => {
    // building column data
    return column_array.push({
      id: item.form_uid,
      width: columns_widths_map.value[item.form_uid] || 150,
      size: columns_widths_map.value[item.form_uid] || 150,
      data: item.form_uid,
      readOnly: true,
      text: item.name,
      header: item.name,

    });
  }, []);
  const activity_obj = {};
  data.forEach((item) => {
    const dataItem = item;
    item.values.forEach(
      (val) => {
        if (!activity_obj[val.uid])
          activity_obj[val.uid] = {};
        activity_obj[val.uid].name = val.name;
        activity_obj[val.uid][dataItem.form_uid] = formatReponseTableData(val);
        activity_obj[val.uid].type = val.type;
        activity_obj[val.uid].field_uid = val.uid;
      },
    );
  });

  activities.value = Object.values(activity_obj);
  columns.value = column_array;
}

function generateActivities(data) {
  const activity_array = [];
  const column_array = [];

  attachment_uids.value = [];

  payload.value.fields.forEach((value) => {
    column_array.push({
      id: value.key,
      width: columns_widths_map.value[value.key] || 150,
      size: columns_widths_map.value[value.key] || 150,
      // type: value.type,
      data: value.key || 'name',
      readOnly: true,
      text: value.label,
      header: value.label,
      ...(value.type === 'attachment' ? { renderer: 'fileUploadRenderer' } : {}),
      ...(value.type === 'long_text' ? { renderer: 'html' } : {}),
    });
  });
  Object.values(data).forEach((value) => {
    const current_item = {};

    Object.keys(value).forEach((valueKey) => {
      if (valueKey === 'values') {
        value[valueKey].forEach((val) => {
          current_item[val.uid] = formatReponseTableData(val);
        });
      }
      else {
        current_item[valueKey] = value[valueKey];
      }
    });

    activity_array.push(current_item);
  });

  activities.value = activity_array;
  columns.value = column_array;
}
const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: props.data.data.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dashboard_selected_range,
    dataFormat: 'json',
    chart_name: props.data.data.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      columns: columns.value,
      activities: activities.value,
      dataset: activities.value,
      is_transpose: props.data.data.transpose === true,
      dashboard_index: props.data.i,
      is_new_pivot_chart: props.data.data.chart === 'workflow_pivot_table',
    },
  });
  dashboard_store.update_new_print_map((props.data?.data?.name || 'untitled'), {
    widget_type: 'table',
    type: props.data?.data?.type,
    dataSource: parseNewPrintData(activities.value),
  });
}

function parseNewPrintData(data) {
  const accessor_keys = columns.value.map(c => c.data);
  const parsed_data = [];
  data.forEach((d) => {
    parsed_data.push(accessor_keys.map(k => toString(d[k])));
  });
  return parsed_data;
}
const colHeaders = function (index) {
  return columns.value[index].text;
};
function columnResized(columns_widths) {
  // prevents the table from rerendering
  prevent_watcher.value = true;
  const columns_width_by_key = columns.value.reduce((acc, col, idx) => {
    acc[col.data] = { size: columns_widths[idx], id: col.data };
    return acc;
  }, {});

  dashboard_store.set_table_column_widths(
    props?.id,
    columns_width_by_key,
  );
}

function handleCellClick(data) {
  const row_data = handsontable_map.value[props.id].getSourceDataAtRow(data.row);
  if (!dashboard_store.is_editing_widget.value) {
    router.push({ query: { ...route.query, form: btoa(JSON.stringify({ form_uid: row_data.form_uid, ...(isUrl(row_data[data?.prop]?.[0]) ? { scroll_to_field_uid: data.prop } : {}), store_key: '' })) } });
  }
}

function isUrl(url) {
  // eslint-disable-next-line regexp/negation, regexp/no-unused-capturing-group
  const url_regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return url_regex.test(url);
}

function cells(row, col, x) {
  const value = this.instance.getDataAtCell(row, col);
  const { fileUploadRenderer } = hotTable.value?.getCustomFunctions();
  const cellProperties = {};
  if (isUrl(value?.[0]))
    cellProperties.renderer = fileUploadRenderer;
  else
    cellProperties.renderer = 'html';
  return cellProperties;
}

watch(() => props.data.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val)) {
    if (prevent_watcher.value) {
      prevent_watcher.value = false;
      return;
    }
    await getReports();
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true, deep: true });

watch(() => props.activeSelectedRange, async (new_val, old_val) => {
  if (!isEqual(new_val, old_val) && (props?.id !== 'preview')) {
    await getReports();
  }
});

watch(() => dashboard_store.is_editing_dashboard, async (new_val) => {
  if (handsontable_map.value[props.id]) {
    handsontable_map.value?.[props.id]?.updateSettings({
      manualColumnResize: new_val,
    });
  }
}, { immediate: true });
</script>

<template>
  <div>
    <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
      <slot name="header-title" />
      <slot name="header-actions" />
    </div>
    <div v-if="no_data" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
      {{ $t('No data present') }}
    </div>
    <hawk-loader v-if="loading" />
    <a v-else-if="activities?.length">
      <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
        <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
          <HawkHandsontable
            ref="hotTable"
            :apply-read-only-class="false"
            :data="activities"
            :columns="columns"
            :hot-settings="hotSettings()"
            :add-new-row-on-enter="false"
            :hot-table-id="id"
            :col-headers="colHeaders"
            :cells="use_custom_cells ? cells : null"
            :height="id !== 'preview' ? '100%' : '450px'"
            @ready="handsontable_map[id] = $event"
            @after-columns-resized="columnResized"
            @cell-dbl-click="handleCellClick"
          />
        </div>
      </div>
    </a>
    <div
      v-else-if="!loading && form_error"
      class="pt-10 w-full flex items-center justify-center"
    >
      <div class="text-sm">
        {{ form_error }}
      </div>
    </div>
  </div>
</template>
