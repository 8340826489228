<script setup>
import { capitalize, isEqual, snakeCase, uniqBy } from 'lodash-es';
import HawkTable from '~/common/components/organisms/hawk-table/hawk-table.vue';
import { convertTableUnitsData } from '~/common/utils/common.utils.js';
import { useDashboardScheduleStore } from '~/dashboard/store/dashboard-schedule.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';

const props = defineProps({
  data: {
    type: Object,
  },
  id: {
    type: String,
  },
  // eslint-disable-next-line vue/prop-name-casing
  content_height: {
    type: Number,
  },
});

const $services = inject('$services');

const dashboard_schedule_store = useDashboardScheduleStore();
const dashboard_store = useDashboardStore();

const force_update = ref(0);
const activities = ref([]);
const columns = ref([]);
const loading = ref(false);
const no_data = ref(false);
const payload = ref(null);
const prevent_watcher = ref(false);
const accessorKey = ref(null);

const height = computed(() => {
  return ((props.data.h || 22) * 20) - 44;
});

const widget_data = computed(() => props.data?.data || {});

async function getReports() {
  loading.value = true;
  activities.value = Object.assign([]);
  columns.value = Object.assign([]);
  payload.value = dashboard_schedule_store.parse_schedule_form_to_server_format(props.data.data);
  accessorKey.value = payload.value?.data?.interval?.name;
  try {
    const { data } = await $services.project_management.get_graph({ body: payload.value });
    if (data.data && Object.keys(data.data).length) {
      generateActivities(data.data);
      no_data.value = false;
    }
    else {
      no_data.value = true;
      columns.value = [];
      activities.value = [];
    }
    loading.value = false;
  }
  catch {
    loading.value = false;
  }
}

function generateActivities({ dataset, categories }) {
  columns.value = [
    {
      accessorKey: accessorKey.value,
      header: capitalize(accessorKey.value),
      id: accessorKey.value,
      size: '150',
    },
  ];
  categories[0].category.forEach((item, key) => {
    const obj = {};
    obj[accessorKey.value] = item.label;
    obj.id = item.label;
    dataset.forEach((s) => {
      const seriesname_key = snakeCase(s.seriesname);
      columns.value.push({
        accessorKey: seriesname_key,
        header: s.seriesname,
        id: seriesname_key,
        size: '150',
      });
      obj[seriesname_key] = convertTableUnitsData(s.data[key]?.value) || 0;
    });
    activities.value.push(obj);
  });
  columns.value = uniqBy(columns.value, 'id');
}

function columnResized(_resized_column, columns_widths) {
  prevent_watcher.value = true;
  dashboard_store.set_table_column_widths(
    props?.id,
    columns_widths,
  );
}
function updatePrintMap() {
  dashboard_store.update_print_map(props.id, {
    type: widget_data.value.type,
    renderAt: `chart-container-${props?.id}`,
    renderType: 'table',
    width: '100%',
    height: '100%',
    dataFormat: 'json',
    chart_name: widget_data.value.name,
    dimensions: {
      x: props.data.x,
      y: props.data.y,
    },
    dataSource: {
      dashboard_index: props.data.i,
      dataset: activities.value,
      columns: columns.value,
      is_transpose: false,
    },
  });
}

watch(() => props.data.data, async (new_val, old_val) => {
  if (new_val && !isEqual(new_val, old_val) && !prevent_watcher.value) {
    await getReports();
    force_update.value++;
    if (props?.id !== 'preview')
      updatePrintMap();
  }
}, { immediate: true, deep: true });
</script>

<template>
  <div>
    <div class="w-full h-full">
      <div v-if="$slots['header-title'] || $slots['header-actions']" class="widget-header group">
        <slot name="header-title" />
        <slot name="header-actions" />
      </div>
      <hawk-loader v-if="loading" />
      <div v-else-if="no_data || !activities?.length" class="text-sm font-semiBold w-full" :class="dashboard_store.is_mobile_view ? 'h-[240px] grid place-items-center' : 'mt-8 flex justify-center'">
        {{ $t('No data present') }}
      </div>
      <a v-else-if="activities && activities?.length && columns?.length">
        <div class="w-full scrollbar" :style="{ height: `${content_height || height}px` }">
          <HawkTable
            :key="force_update"
            :pagination_config="{ totalRows: activities.length, pageSize: 50 }"
            :data="activities"
            :columns="columns"
            :show_menu_header="false"
            :show_column_borders="true"
            :container_class="`h-[${content_height || height}px]`"
            :striped="true"
            :disable_resize="!dashboard_store.is_editing_dashboard"
            freeze_column_id="name"
            additional_table_classes=""
            is_gapless
            cell_height="30px"
            @column-resized="columnResized"
          />
        </div>
      </a>
    </div>
  </div>
</template>
